import { css } from 'styled-components';

const sizes = { bigDesktop: 1200, desktop: 1024, tablet: 768, phone: 576 };

export const encode = data => Object.keys(data)
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

export const getAsHTML = data => ({ __html: data });
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
