import tinycolor from 'tinycolor2';

const textColor = '#543f54';

const brand = {
  primary: '#A0EDFF',
  secondary: '#FF9FC0',
  alternative: '#9285E8',
  accent: '#F8FF92',
  alternativeAccent: '#FAC37F'
};

export default {
  brand,
  text: {
    primary: tinycolor(textColor).toHexString(),
    secondary: tinycolor(textColor)
      .desaturate(10)
      .lighten(40)
      .toHexString()
  }
};
