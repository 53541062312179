import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Header from '@/components/header';
import Colors from '@/assets/colors';
import './index.less';

const Container = styled.main`
  color: ${Colors.text.primary};
`;

const Layout = ({ children, location }) => {
  const [section, setSection] = useState(location.pathname.split('/').filter(path => !!path).pop());
  const seoKeywords = [
    'frontend',
    'backend',
    'javascript',
    'js',
    'feathers',
    'mongodb',
    'mongo',
    'postgres',
    'postgresql',
    'react',
    'vuejs',
    'developer',
    'freelance'
  ];

  useEffect(() => {
    const { pathname } = location;
    const section = pathname.split('/').filter(path => !!path).pop();
    setSection(section);
  }, [location]);

  return (
    <Container>
      <Helmet
        title='Silvestre △ Software Developer'
        bodyAttributes={{ class: section || 'index' }}
        meta={[
          { name: 'description', content: 'Fullstack JS developer from Argentina' },
          { name: 'keywords', content: seoKeywords.join(',') },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]} />
      <Header location={location} />
      {children}
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default Layout;
