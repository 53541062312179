import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import styled, { css } from 'styled-components';

import colors from '@/assets/colors';

const Path = styled.path`
  fill: none;
  stroke-linecap: round;
  stroke-width: ${({ size }) => size === 'small' ? '5px' : '2px'};;
  stroke-linejoin: round;
`;

const NamePath = styled(Path)`
  stroke: ${colors.brand.secondary};
`;

const TrianglePath = styled(Path)`
  stroke: ${colors.brand.primary};
`;

const Svg = styled.svg`
  display: inline-block;
  height: auto;
  max-height: 60vh;
  transition: opacity 25ms;
  vertical-align: middle;
  will-change: opacity;

  ${({ loaded, size }) => css`
    opacity: ${loaded ? 1 : 0};
    width: ${size === 'small' ? '4rem' : '38vw'};
  `};
`;

const Logo = ({ size }) => {
  const [loaded, setLoaded] = useState(false);
  const viewBox = size === 'small'
    ? '383.222 190 514.555 425'
    : '383.222 188.073 514.555 423.927';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 25);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const config = {
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 250,
      delay: (_, i) => i * 25,
      direction: 'alternate',
      loop: false
    };

    anime({
      ...config,
      targets: '#Silvestre path'
    });

    anime({
      ...config,
      targets: '#Herrera path',
      delay: 500,
      duration: 350
    });

    anime({
      ...config,
      targets: '#Triangle path',
      duration: 500,
      delay: 750
    });
  }, [loaded]);

  return (
    <Svg loaded={loaded} size={size} className="logo" viewBox={viewBox}>
      <g id="Triangle" transform="translate(0 33)">
        <TrianglePath size={size} d="M55.2,335,1.8,422.9h511l-53.5-88.1" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '716.92138671875px' }} />
        <TrianglePath size={size} d="M347,149.8,257.3,1.9l-89.9,148" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '346.14013671875px' }} />
      </g>
      <g id="Herrera" transform="translate(0 33)">
        <g>
          <NamePath size={size} d="M410.3,334.4l28.5-74,29.7,74" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '159.0361328125px' }} />
          <NamePath size={size} d="M24.7,62.2,0,0" transform="translate(817.6 427.3)" style={{ strokeDashoffset: '66.92481231689453px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V73" transform="translate(746.5 417)" style={{ strokeDashoffset: '73px' }} />
          <NamePath size={size} d="M363.5,292.4h19.1c8.4,0,15.1-8.5,15.1-16.9a15.087,15.087,0,0,0-15.1-15.1H355.7v74" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '168.70970153808594px' }} />
          <NamePath size={size} d="M398.1,334.9l-14.8-32h-20" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '55.25676727294922px' }} />
        </g>
        <g>
          <NamePath size={size} d="M344.3,334.9h-46v-74h46" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '166px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(681.5 448)" style={{ strokeDashoffset: '37px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(681.5 458)" style={{ strokeDashoffset: '37px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V73" transform="translate(628.5 417)" style={{ strokeDashoffset: '73px' }} />
          <NamePath size={size} d="M245.5,292.4h19.1c8.4,0,15.1-8.5,15.1-16.9a15.087,15.087,0,0,0-15.1-15.1H237.7v74" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '168.709716796875px' }} />
          <NamePath size={size} d="M280.1,334.9l-14.8-32h-20" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '55.25676727294922px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V73" transform="translate(567.5 417)" style={{ strokeDashoffset: '73px' }} />
          <NamePath size={size} d="M184.5,292.4h19.1c8.4,0,15.1-8.5,15.1-16.9a15.087,15.087,0,0,0-15.1-15.1H176.7v74" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '168.709716796875px' }} />
          <NamePath size={size} d="M219.1,334.9l-14.8-32h-20" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '55.25676727294922px' }} />
        </g>
        <g>
          <NamePath size={size} d="M165.3,334.9h-46v-74h46" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '166px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(502.5 448)" style={{ strokeDashoffset: '37px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(502.5 458)" style={{ strokeDashoffset: '37px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V74" transform="translate(438.5 416)" style={{ strokeDashoffset: '74px' }} />
          <NamePath size={size} d="M0,0V74" transform="translate(484.5 416)" style={{ strokeDashoffset: '74px' }} />
          <NamePath size={size} d="M46,0H0" transform="translate(438.5 448)" style={{ strokeDashoffset: '46px' }} />
          <NamePath size={size} d="M46,0H0" transform="translate(438.5 458)" style={{ strokeDashoffset: '46px' }} />
        </g>
      </g>
      <g id="Silvestre" transform="translate(0 33)">
        <g>
          <NamePath size={size} d="M497.3,223.9h-46v-74h46" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '166px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(834.5 337)" style={{ strokeDashoffset: '37px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(834.5 347)" style={{ strokeDashoffset: '37px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V73" transform="translate(785.5 306)" style={{ strokeDashoffset: '73px' }} />
          <NamePath size={size} d="M402.5,181.4h19.1c8.4,0,15.1-8.5,15.1-16.9a15.087,15.087,0,0,0-15.1-15.1H394.7v74" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '168.70974731445312px' }} />
          <NamePath size={size} d="M437.1,223.9l-14.8-32h-20" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '55.25676727294922px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V64" transform="translate(736.5 315)" style={{ strokeDashoffset: '64px' }} />
          <NamePath size={size} d="M45,0H0" transform="translate(714.5 305)" style={{ strokeDashoffset: '45px' }} />
          <NamePath size={size} d="M45,0H0" transform="translate(714.5 315)" style={{ strokeDashoffset: '45px' }} />
        </g>
        <g>
          <NamePath size={size} d="M267.9,212.3s8.7,11.2,26.9,11.2c11.5,0,24.9-5.8,24.9-19.1,0-14-13.2-15.8-26.1-19.1-12.9-3.2-23.3-5.5-23.3-18.2,0-6.9,5.9-17.6,23.2-17.6,13.8,0,24.2,7.9,24.2,7.9" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '191.4697723388672px' }} />
        </g>
        <g>
          <NamePath size={size} d="M261.3,223.9h-46v-74h46" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '166px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(598.5 337)" style={{ strokeDashoffset: '37px' }} />
          <NamePath size={size} d="M37,0H0" transform="translate(598.5 347)" style={{ strokeDashoffset: '37px' }} />
        </g>
        <g>
          <NamePath size={size} d="M142.3,149.4l28.5,74,29.7-74" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '159.03611755371094px' }} />
          <NamePath size={size} d="M24.7,0,0,62.3" transform="translate(549.6 304.5)" style={{ strokeDashoffset: '67.01776123046875px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V74" transform="translate(499.5 305)" style={{ strokeDashoffset: '74px' }} />
          <NamePath size={size} d="M106.3,149.9v74h46" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '120px' }} />
        </g>
        <g>
          <NamePath size={size} d="M0,0V74" transform="translate(468.5 305)" style={{ strokeDashoffset: '74px' }} />
        </g>
        <g>
          <NamePath size={size} d="M17.9,212.3s8.7,11.2,26.9,11.2c11.5,0,24.9-5.8,24.9-19.1,0-14-13.2-15.8-26.1-19.1-12.9-3.2-23.3-5.5-23.3-18.2,0-6.9,5.9-17.6,23.2-17.6,13.8,0,24.2,7.9,24.2,7.9" transform="translate(383.2 155.1)" style={{ strokeDashoffset: '191.4697723388672px' }} />
        </g>
      </g>
    </Svg>
  );
};

Logo.defaultProps = {
  size: 'large'
};

Logo.propTypes = {
  size: PropTypes.oneOf([
    'large',
    'small'
  ])
};

export default Logo;
