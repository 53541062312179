import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import colors from '@/assets/colors';
import Logo from '@/components/logo';
import { media } from '@/utils';

const Container = styled.nav`
  align-items: center;
  display: flex;
  font-size: 1em;
  justify-content: center;
  padding: 2em 0;
  position: relative;
  transition: transform 250ms;
  will-change: transform;
  z-index: 10;

  ${media.phone`
    font-size: .75em;
    padding: 1em 0;
    transform: translateY(5em);
  `}

  .logo-warpper {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    ${media.phone`
      bottom: 100%;
      top: auto;
      transform: translate(-50%, 25%);
    `}
  }

  .index & {
    transform: translateY(0);
  }
`;

const NavItem = styled(Link)`
  color: ${colors.brand.alternative};
  font-family: 'Museo Sans', sans-serif;
  font-weight: 100;
  padding: 1em;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform 500ms, color 500ms;

  ${media.phone`
    font-weight: 500;
  `}

  &:nth-child(1), &:nth-child(2) {
    transform: translateX(-4em);

    ${media.phone`
      transform: translateX(0);
    `}
  }

  &:nth-child(3), &:nth-child(4) {
    transform: translateX(3em);

    ${media.phone`
      transform: translateX(0);
    `}
  }

  .index & {
    &:nth-child(1), &:nth-child(2) {
      transform: translateX(0);
    }

    &:nth-child(3), &:nth-child(4) {
      transform: translateX(0);
    }
  }

  &::after {
    background: ${colors.brand.primary};
    bottom: 0;
    content: '';
    height: .125em;
    left: 1em;
    position: absolute;
    transform: scaleX(0);
    transition: transform 250ms;
    right: 1em;
    will-change: transform;
  }

  &.active {
    color: ${colors.brand.secondary};
    position: relative;

    &::after {
      transform: scaleX(1);
    }
  }
`;

const items = [
  { key: '/resume/', label: 'Résumé' },
  { key: '/projects/', label: 'Projects' },
  { key: '/about/', label: 'About' },
  { key: '/contact/', label: 'Contact' }
];

const Header = ({ location }) => {
  return (
    <Container className='header'>
      {items.map(item => (
        <NavItem activeClassName='active' className='header__item' {...item} to={item.key}>
          {item.label}
        </NavItem>
      ))}
      {location.pathname !== '/' &&
        <NavItem className='logo-warpper' to='/'>
          <Logo size='small' />
        </NavItem>
      }
    </Container>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired
};

export default Header;
